import React from 'react'
import Box from 'components/Box'
import Container from 'components/Container'
import Head from 'components/Head'
import Layout from 'components/Layout'
import View from 'components/View'
import Auth from 'features/auth/components/Auth'
import useEventPageVisited from 'hooks/use-event-page-visited'

export default function SignUp() {
  useEventPageVisited('Sign up')

  return (
    <Layout className="SignUp" data-test-id="sign-up">
      <View flush>
        <Head
          title="Join Bold, and get a free balance or strength report in minutes"
          description="Test your strength or balance in minutes and learn exercises to for healthier aging and to feel your best. We teach classes that can be done from anywhere."
        />
        <Container size="xlarge">
          <Box flush>
            <Auth type="sign-up" />
          </Box>
        </Container>
      </View>
    </Layout>
  )
}
